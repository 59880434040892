import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Button from 'components/Button';

const renderCardData = (
  path,
  fullWidth,
  location,
  date,
  time,
  buttons,
  details
) => (
  <div
    className={`${details ? 'beach-details ' : 'beach'} cursor-pointer`}
    onClick={() => path && navigate(path)}
    role="presentation"
  >
    <div className={`text ${fullWidth ? 'textWrapper' : ''}`}>
      <div>
        <h2 className="mb-0">{location}</h2>
        {date && <p>{date}</p>}
        {time && <p>{time}</p>}
      </div>
      <div>
        {buttons?.length &&
          buttons.map((button, index) => (
            <Button
              key={index}
              type="app-default-white-button"
              linkAsButton={true}
              path={button.slug}
            >
              {button.text}
            </Button>
          ))}
      </div>
    </div>
  </div>
);

const Card = ({
  img,
  location,
  date,
  path,
  fullWidth,
  time,
  buttons,
  length,
  details,
}) => {
  useEffect(() => {
    function handleContextMenu(e) {
      e.preventDefault(); // prevents the default right-click menu from appearing
    }
    // add the event listener to the component's root element
    const rootElement = document.getElementById('my-component');
    rootElement.addEventListener('contextmenu', handleContextMenu);
    // remove the event listener when the component is unmounted

    return () => {
      rootElement.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  if (typeof window !== 'undefined') {
    document.addEventListener('contextmenu', e => {
      if (e.target.tagName === 'DIV') {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
  return (
    <div
      id="my-component"
      className={fullWidth ? '' : length === 1 ? 'cardMaxWidth' : ''}
    >
      {img ? (
        <BackgroundImage fluid={img}>
          {renderCardData(
            path,
            fullWidth,
            location,
            date,
            time,
            buttons,
            details
          )}
        </BackgroundImage>
      ) : (
        renderCardData(path, fullWidth, location, date, time, buttons)
      )}
    </div>
  );
};
export default Card;
